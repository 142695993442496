#blog {
  margin-top: 0;
}

.blog__container {
  padding-top: 100px;
  display: grid;
  grid-template-columns: 80% 20%;
}

.blog__posts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blog__post {
  margin: 3.5rem 3rem;
  position: relative;
}

.blog__post::after {
  content: "";
  position: absolute;
  bottom: -10%;
  left: 25%;
  width: 50%;
  border-bottom: 2px solid var(--color-dark-gold);
}

.blog__posts h1 {
  color: var(--color-dark-gold);
  font-size: 2.5rem;
  font-weight: 600;
  margin: 0.5rem auto;
  text-align: center;
}

.blog__posts h2 {
  color: var(--color-dark-gold);
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0.5rem auto;
  text-align: center;
}

.blog__posts p {
  color: var(--color-white);
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0.5rem auto;
  text-align: center;
}

.blog__sidebar {
  height: 70vh;
  width: 15vw;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
  top: 20vh;
  right: 2.5vw;

  border-left: 1px solid var(--color-gold);
  border-right: 1px solid var(--color-gold);

  overflow-y: auto;
}

.blog__sidebar::-webkit-scrollbar {
  width: 0.5rem;
}

.blog__sidebar li {
  margin: 1rem 0;
}

.blog__sidebar a {
  text-decoration: none;
  color: var(--color-gold);
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0.5rem 0;
}

.blog__sidebar a:hover {
  color: var(--color-white);
}

.blog__sidebar h2 {
  color: var(--color-dark-gold);
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0.5rem 0;
}

/****** MEDIA QUERIES (MEDIUM DEVICES) ********/
@media screen and (max-width: 1024px) {
  #blog {
    width: 100%;
    margin-top: 0;
  }
  .blog__container {
    grid-template-columns: 100%;
  }

  .blog__sidebar {
    display: none;
  }

  .blog__posts {
    width: 100%;
  }

  .blog__post {
    margin: 3.5rem 0;
  }
}

/****** MEDIA QUERIES (SMALL DEVICES) ********/
@media screen and (max-width: 600px) {
  .blog__sidebar {
    display: none;
  }

  .blog__posts {
    width: 100%;
  }

  .blog__post {
    margin: 3.5rem 1rem;
  }

  .blog__post::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 25%;
    width: 50%;
    border-bottom: 2px solid var(--color-dark-gold);
  }
}
