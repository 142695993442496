.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 32rem;
  width: 39rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.mapouter {
  position: relative;
  text-align: right;
  height: 32rem;
  width: 39rem;
}

.container.contact__container {
  width: 90%;
  display: grid;
  grid-template-columns: 20% 20% 50%;
  gap: 5%;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin: 3rem 0;
}

.contact__option {
  background: linear-gradient(
    -45deg,
    var(--color-gold),
    var(--color-dark-gold)
  );
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid var(--color-brown);
  transition: var(--transition);
}

.contact__option:hover {
  background: transparent;
  border-color: var(--color-dark-gold);
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option a {
  display: inline-block;
  font-size: 0.8rem;
  color: var(--color-white);
}

.contact__option h5 {
  font-size: 1vw;
}

/****** MEDIA QUERIES (MEDIUM DEVICES) ********/
@media screen and (max-width: 1024px) {
  .mapouter {
    width: 600px;
    height: 500px;
  }
  .map_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4rem 0;
  }
  .container.contact__container {
    height: 100%;
    width: var(--container-width-md);
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  .contact__options {
    margin: 0;
    display: grid;
    grid-template-columns: 50% 50%;
  }
}

/****** MEDIA QUERIES (SMALL DEVICES) ********/
@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  .contact__options {
    margin: 0;
    display: flex;
    align-items: center;
  }
  .contact__option {
    width: 75%;
  }
  .map_container {
    width: 100%;
    height: 50%;
    margin-top: 2rem;
  }
  .mapouter {
    width: 100%;
  }
  .gmap_canvas {
    width: 100%;
    height: 50%;
  }
  .contact__option h5 {
    font-size: 2.5vw;
  }
}
