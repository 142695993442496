.about {
  padding-top: 15vh;
  padding-left: 5vw;
  padding-right: 5vw;
  margin: 0;

  h1 {
    color: var(--color-dark-gold);
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0.5rem auto;
    text-align: center;
  }

  h2 {
    color: var(--color-dark-gold);
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0.5rem auto;
    text-align: center;
  }

  p {
    font-size: 16px;
    margin: 16px 20px;

    strong {
      font-weight: bold;
    }
  }
}
