#owner {
  background-image: url("../../assets/img/owner_background.webp");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.owner__container {
  display: grid;
  grid-template-columns: 5% 25% 41% 5%;
  gap: 8%;
}

.owner__image {
  width: 120%;
  height: 150%;
}

.owner__image > img {
  border-radius: 50%;
}

.owner__content {
  margin: 3rem 0 2rem;
  color: var(--color-light);
}

p > a {
  color: var(--color-dark-gold);
}

button > a {
  color: var(--color-light);
  text-decoration: none;
}

/****** MEDIA QUERIES (MEDIUM DEVICES) ********/
@media screen and (max-width: 1024px) {
  #owner {
    height: 100%;
  }
  .owner__container {
    grid-template-columns: 40% 50%;
    gap: 10%;
  }

  .extra__div {
    display: none;
  }

  .owner__content {
    margin: 3rem 3rem 0 0;
  }

  .owner__image {
    width: 100%;
    height: 100%;
  }
}

/****** MEDIA QUERIES (SMALL DEVICES) ********/
@media screen and (max-width: 600px) {
  #owner {
    height: 100%;
  }
  .owner__cards {
    display: flex;
    flex-direction: column; /* Change to a single column layout */
    gap: 1rem;
  }

  .owner__content {
    text-align: center;
    margin: 0;
  }

  .owner__content p {
    font-size: 1.1rem;
    padding: 1rem;
    margin: 0;
  }
  .owner__content button {
    display: inline;
    width: 40%;
    height: 10%;
  }

  .owner__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 3%;
  }
  .owner__image {
    width: 80%;
    height: 80%;
  }
}
