.topnav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-black);
  overflow: hidden;
  gap: 0.8rem;
  width: 100%;
  z-index: 2;
  position: fixed;
}

.topnav .logo {
  height: 100px;
  margin-left: 1rem;
}

.topnav ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.topnav li {
  margin: 0 0.5rem;
}

.topnav a {
  color: var(--color-white);
  background-color: transparent;
  text-align: center;
  padding: 0.9rem 1rem;
  text-decoration: none;
  font-size: 1.1rem;
}

.topnav a:hover {
  background-color: var(--color-white);
  color: var(--color-dark-gold);
}

.topnav a.active {
  background-color: var(--color-gold);
  color: var(--color-white);
}

/****** MEDIA QUERIES (MEDIUM DEVICES) ********/
@media screen and (max-width: 1024px) {
  .topnav {
    align-items: center;
    gap: 0.5rem;
    position: relative;
  }

  .topnav .logo {
    height: 80px;
    margin: 0;
  }

  .topnav ul {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-right: 0.5rem;
  }

  .topnav li {
    margin: 0;
  }

  .topnav a {
    padding: 0.5rem 1rem;
  }
}

/****** MEDIA QUERIES (SMALL DEVICES) ********/
@media screen and (max-width: 600px) {
  .topnav {
    padding: 0.5rem;
    width: 100%;
    position: fixed;
  }

  .topnav .logo {
    height: 50px; /* Adjust the value as needed */
    margin-left: 0;
  }

  .topnav ul {
    flex-direction: row;
    justify-content: center;
  }

  .topnav li {
    margin: 0 0.3rem;
  }

  .topnav a {
    padding: 0.5rem 0.7rem; /* Adjust the value as needed */
    font-size: 1rem; /* Adjust the value as needed */
  }
}
