#about {
  padding: 0 5vh;
}

.about__info {
  height: 100% !important;
  display: grid;
  grid-template-columns: [line1] 50% [line2] 50%;
  grid-template-rows: [line1] 50% [line2] 50%;
  gap: 2rem;
  margin: 3rem auto;

  p {
    padding: 2rem 0.5rem;
    margin: 0.5rem;
    color: var(--color-light);
    display: flex;
    justify-content: center;
    align-self: center;
    border-top: 1px solid var(--color-light);
    border-bottom: 1px solid var(--color-light);
  }

  img {
    margin: 1rem 1rem;
    width: 20rem;
    height: 40rem;
  }
}

.item-a {
  display: flex;
  grid-column: 1;
  grid-row: 1;
}

.item-b {
  display: flex;
  grid-column: 2;
  grid-row: 1;
}

.item-c {
  display: flex;
  grid-column: 1 / span 2;
  grid-row: 2;
}

/****** MEDIA QUERIES (MEDIUM DEVICES) ********/
@media screen and (max-width: 1024px) {
  .about__info {
    height: 100% !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;

    img {
      margin: 1rem auto;
      width: 14rem;
      height: 28rem;
      display: block;
    }
    .item-a,
    .item-b {
      display: grid;
      grid-template-columns: 50% 50%;
      align-items: center;
      justify-content: center;
    }
    .item-c {
      align-items: center;
      justify-content: center;
    }

    .item-a > img,
    .item-b > img {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

/****** MEDIA QUERIES (SMALL DEVICES) ********/
@media screen and (max-width: 600px) {
  .about__info {
    height: 100% !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;

    img {
      margin: 1rem 1rem;
      width: 12rem;
      height: 24rem;
    }
    .item-a,
    .item-b,
    .item-c {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
