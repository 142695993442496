.intro-section {
  padding: 4rem;
  text-align: center;
}

.intro-content {
  font-size: 1.2rem;
  line-height: 1.6;

  max-width: 850px;
  margin: 0 auto;
}

.intro-content > p {
  padding: 0.5rem;
  border-top: 1px solid var(--color-light);
  border-bottom: 1px solid var(--color-light);
}

/****** MEDIA QUERIES (MEDIUM DEVICES) ********/
@media screen and (max-width: 1024px) {
  .intro-section {
    padding: 4rem;
    text-align: center;
    margin: 0;
  }
}

/****** MEDIA QUERIES (SMALL DEVICES) ********/
@media screen and (max-width: 600px) {
  .intro-section {
    padding: 2rem;
    text-align: center;
    margin: 0;
  }
}
