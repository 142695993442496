@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lovers+Quarrel&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-white: #fff;
  --color-light: #f4f4f4;
  --color-gold: #f0a500;
  --color-dark-gold: #cf7500;
  --color-brown: #4e3524;
  --color-black: #1a1c20;

  --transition: all 100ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-light);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: var(--color-gold);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-dark-gold);
}

body {
  font-family: "Poppins", sans-serif;
  background: var(--color-dark-gold);
  color: var(--color-white);
  line-height: 1.7;
  background-color: var(--color-brown);
}

/******* GENERAL STYLES *********/

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
  height: 80vh;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
}

section > h2,
section > h5 {
  text-align: center;
  color: var(--color-gold);
  font-family: "Lovers Quarrel", cursive;
  font-size: 48px;
}

section > h2 {
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-gold);
  transition: var(--transition);
}

a:hover {
  color: var(--color-gold);
}

.btn {
  width: max-content;
  display: block;
  color: var(--color-dark-gold);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-light);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-dark-gold);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/****** MEDIA QUERIES (MEDIUM DEVICES) ********/
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

/****** MEDIA QUERIES (SMALL DEVICES) ********/
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section {
    margin-top: 2rem;
  }
}
