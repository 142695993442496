#testimonials {
  background-image: url("../../assets/img/testimonials_background.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 120vh;
}

.testimonials__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
}

.testimonial__block {
  height: 100%;
  border: 2px solid var(--color-dark-gold);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--color-brown);
  opacity: 0.9;

  border-radius: 5px;
}

@media screen and (max-width: 1024px) {
  #testimonials {
    height: 100%;
    background-repeat: repeat-y;
    background-size: cover;
  }
  #testimonials > h2 {
    padding: 0.5rem 1rem;
  }

  .testimonials__container {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
    padding-bottom: 5%;
  }

  .testimonial__block {
    height: 100%;
  }
}

/****** MEDIA QUERIES (SMALL DEVICES) ********/
@media screen and (max-width: 600px) {
  #testimonials {
    height: 100%;
    background-repeat: repeat-y;
    background-size: contain;
  }
  #testimonials > h2:first-child {
    padding: 0.5rem 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    text-shadow: 0 0 5px var(--color-black);
    line-height: 3rem;
  }

  .testimonials__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0 2.5rem;
  }

  .testimonial__block {
    height: 100%;
    margin: 1vh 0;
  }
}
