header {
  height: 100vh;
  width: 100%;
  padding-top: 100px;
  overflow: hidden;
}

.header__container {
  height: 100%;
  position: relative;
}

.carousel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: flex-end;
}

.carousel__image {
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.carousel .slick-slide {
  display: flex;
  justify-content: center;
}

.carousel .slick-slide img {
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  margin: 0 auto;
}

.carousel__image.active {
  display: block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.header__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  font-size: 48px;
  font-weight: bold;
  color: var(--color-gold);
  text-align: center;
  z-index: 1;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  text-shadow: 0 0 10px rgba(0, 0, 0);
}

/****** MEDIA QUERIES (MEDIUM DEVICES) ********/
@media screen and (max-width: 1024px) {
  header {
    height: 80vh;
    width: 100%;
    padding-top: 0px;
  }

  .carousel {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .header__text {
    font-size: 36px; /* Adjust the value as needed */
  }
}

/****** MEDIA QUERIES (SMALL DEVICES) ********/
@media screen and (max-width: 600px) {
  header {
    height: 100%; /* Adjust the value as needed */
    padding-top: 60px; /* Adjust the value as needed */
  }
  .header__container {
    height: 100%; /* Adjust the value as needed */
  }

  .carousel {
    align-items: center;
  }

  .carousel__image {
    object-fit: contain; /* Adjust the value as needed */
  }

  .header__text {
    font-size: 24px; /* Adjust the value as needed */
  }
}
