.news-container {
  display: flex;
  flex-direction: column;

  gap: 50px;
}

.facebook-container,
.tik-tok-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 10px;
  margin: 15px;
  margin-top: 100px;

  h1 {
    color: var(--color-gold);
    margin: 5px;
  }
}

.facebook-frame {
  border: none;
  overflow: hidden;
  max-width: 780px;
  min-width: 188px;
  width: 500px;
  height: 500px;
}
